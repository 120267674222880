import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect} from "react";
import {useAuth} from "../../context/AuthContext";
import styles from './login.module.css';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const {signIn} = useAuth();

  useEffect(() => {
    if (location?.hash?.length > 1) {
      const token = location.hash.split("#")[1];
      // assume token is valid
      localStorage.setItem("spotify_token", token);
      signIn(token);
      navigate('home');
    }
  }, [location, signIn, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img alt="" className={styles.logoSpotify} src='/spotify-logo.png'/>
        <a className={`${styles.btnSpotify} ${styles.login}`} href='/auth/login'>
          Login
        </a>
      </div>
    </div>
  );
}

export default Login;
