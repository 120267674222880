import {usePlayerContext} from "../../context/PlayerContext";
import styles from './nowplaying.module.css';
import {IoChevronBack} from "react-icons/all";
import {useNavigate} from "react-router-dom";

const NowPlaying = () => {
  const navigate = useNavigate()
  const {state} = usePlayerContext();

  return (
    <div className={styles.container}>
      <div
        onClick={() => navigate(-1)}
        className={styles.btnReturn}
        role={"button"}
      >
        <IoChevronBack color={"white"} size={"30px"}/>
      </div>
      <div className={styles.imgContainer}>
        <img src={state.currentTrack?.album?.images[0]?.url} alt=""/>
      </div>
    </div>
  );
};

export default NowPlaying;