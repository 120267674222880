import React, {createContext, useCallback, useContext, useEffect, useReducer, useRef} from 'react';
import {
  getArtist,
  getPlaybackState,
  getPlaylistTracks,
  getTrackAudioAnalysis,
  getUserPlaylists,
  PlayTracks
} from "../scripts/api";
import {processTrackData} from "../scripts/helpers";
import * as Unity from "../scripts/unity";
import {useAuth} from "./AuthContext";

const PlayerContext = createContext();
PlayerContext.displayName = "PlayerContext";

const ACTIONS = {
  PLAYER_INIT: 1,
  PLAYER_STATE_CHANGED: 2,
  SET_DEVICE_ID: 3,
  PLAYLISTS_DATA_RETURNED: 4,
  SET_TRACKS: 7,
  SYNC_STATUS: 8,
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.PLAYER_INIT: {
      return {
        ...state,
        player: action.payload.player,
      }
    }
    case ACTIONS.PLAYER_STATE_CHANGED: {
      return {
        ...state,
        paused: action.payload.paused,
        playbackStatus: action.payload.playbackStatus,
        currentTrack: action.payload.playbackStatus.current_track || null,
      }
    }
    case ACTIONS.SET_DEVICE_ID: {
      return {
        ...state,
        deviceId: action.payload.deviceId,
      }
    }
    case ACTIONS.PLAYLISTS_DATA_RETURNED: {
      return {
        ...state,
        playlists: action.payload.playlists,
      }
    }
    case ACTIONS.SET_TRACKS: {
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          [action.payload.id]: action.payload.items,
        }
      }
    }
    default:
      return state;
  }
}

const initialState = {
  player: null,
  paused: true,
  deviceId: null,
  playbackStatus: null,
  currentTrack: null,
  playlists: [],
  playlistTracks: [],
}

const PlayerContextProvider = ({children}) => {
  const playerRef = useRef(null);
  const {isLoggedIn, token} = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  const next = useCallback(async () => {
    await playerRef.current?.nextTrack();
  }, [])

  const previous = useCallback(async () => {
    await playerRef.current?.previousTrack();
  }, [])

  const play = useCallback(async () => {
    await playerRef.current?.togglePlay();
    state.paused ? Unity.sendMessage.play() : Unity.sendMessage.pause();
  }, [state.paused])

  const initUserPlaylists = async () => {
    const playlists = await getUserPlaylists();
    dispatch({
      type: ACTIONS.PLAYLISTS_DATA_RETURNED, payload: {
        playlists: playlists.items
      }
    });
    Unity.sendMessage.playlists(playlists.items);
  }

  // when you click play on a playlist without going into it
  const onPlayPlaylist = async (id) => {
    const config = {
      context_uri: `spotify:playlist:${id}`
    }
    await PlayTracks(state.deviceId, config)
    Unity.sendMessage.play();
  }

  // play tracks at specified index
  const onPlayTracks = async (playlistId, config = {}) => {
    await onSelectPlaylist(playlistId);
    const tracks = []
    state.playlistTracks[playlistId] &&
    state.playlistTracks[playlistId].forEach((item) => {
      if (item.track) {
        tracks.push(item.track.uri)
      }
    });
    const newConfig = {
      uris: tracks,
      ...config,
    }
    await PlayTracks(state.deviceId, newConfig);
    Unity.sendMessage.play();
  };


  // fetch playlist information when navigating into it
  const onSelectPlaylist = async (playlistId) => {
    const data = await getPlaylistTracks(playlistId)
    dispatch({
      type: ACTIONS.SET_TRACKS, payload: {
        id: playlistId,
        items: data.items,
      }
    })
  }

  // create player
  // depends on token since we need to re-instantiate every time we issue a new token
  useEffect(() => {
    if (Boolean(token)) {
      createSpotifyPlayer();
    }

    function createSpotifyPlayer() {
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      script.async = true;

      document.body.appendChild(script);

      window.onSpotifyWebPlaybackSDKReady = () => {
        const player = new window.Spotify.Player({
          name: 'Moonshot VR Player',
          getOAuthToken: (cb) => {
            cb(token);
          },
          volume: 0.5,
        });
        playerRef.current = player;

        player.addListener('ready', ({device_id}) => {
          dispatch({type: ACTIONS.SET_DEVICE_ID, payload: {deviceId: device_id}})
        });

        player.addListener('not_ready', ({device_id}) => {
          console.log('Device ID has gone offline', device_id);
        });

        player.addListener('player_state_changed', async (state) => {
          if (!state) return;
          dispatch({
            type: ACTIONS.PLAYER_STATE_CHANGED, payload: {
              playbackStatus: state.track_window,
              paused: state.paused
            }
          });
        });

        player.connect();
      };
    }

    // eslint-disable-next-line
  }, [token])

  // send info to unity every song change
  useEffect(() => {
    const sendDataAboutSongToUnity = async () => {
      const audioAnalysis = await getTrackAudioAnalysis(state.playbackStatus?.current_track.id);
      const artistId = state.currentTrack.artists[0].uri.split(":")[2];
      const artistInfo = await getArtist(artistId);
      const analysis = processTrackData(audioAnalysis);
      const currentTrack = state.currentTrack;
      const data = {
        ...analysis,
        genres: artistInfo.genres,
        song_name: currentTrack.name,
        album: currentTrack.album.name,
        img: currentTrack.album.images[0].url,
      };
      return data;
    }
    if (state.playbackStatus?.current_track?.id) {
      sendDataAboutSongToUnity().then(data => {
        Unity.sendMessage.trackPlaying(data);
      });
    }

  }, [state.playbackStatus?.current_track?.id])

  // when user is logged in, retrieve playlists
  useEffect(() => {
    if (isLoggedIn)
      initUserPlaylists();
  }, [isLoggedIn]);

  // vuplex listener
  useEffect(() => {
    if (window.vuplex) {
      addMessageListener();
    } else {
      window.addEventListener('vuplexready', addMessageListener);
    }

    function addMessageListener() {
      if (!Boolean(state.deviceId)) return;
      window.vuplex.addEventListener('message', function (event) {
        const payload = JSON.parse(event.data);
        switch (payload.type) {
          default:
            console.log("Could not get event for " + payload.type);
        }
      });
    }

  }, [state.deviceId])

  // Fetch information about playback state every 5 seconds.
  useEffect(() => {
    const unmount = setInterval(async () => {
      if (!isLoggedIn || !state.isPlaying) return;
      const data = await getPlaybackState();
      if (data) {
        const payload = {
          duration_ms: data.item.duration_ms,
          progress_ms: data.progress_ms,
          is_playing: data.is_playing,
        }
        Unity.sendMessage.sync(payload);
      }
    }, 5000);
    return () => {
      clearInterval(unmount);
    }
  }, [isLoggedIn, state.isPlaying]);

  return <PlayerContext.Provider value={{
    state,
    player: playerRef.current,
    next,
    previous,
    play,
    onPlayPlaylist,
    onPlayTracks,
    onSelectPlaylist,
  }}>
    {children}
  </PlayerContext.Provider>
}

const usePlayerContext = () => {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error('usePlayerContext must be used within a PlayerContextProvider')
  }
  return context
}

export {
  PlayerContextProvider,
  usePlayerContext
}
