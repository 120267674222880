import axios from 'axios';

const SPOTIFY_BASEURL = 'https://api.spotify.com/v1'

const getToken = () => {
  try {
    const token = localStorage.getItem("spotify_token");
    return token;
  } catch (e) {
    console.log("Error getting token");
  }
}

/**
 Spotify Web PlayBack SDKs
 */
const SpotifyAPI = axios.create({
  baseURL: SPOTIFY_BASEURL, headers: {
    'Content-Type': 'application/json',
  },
})

SpotifyAPI.interceptors.request.use((config) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

SpotifyAPI.interceptors.response.use((response) => response, async (error) => {
  if (error.response.status === 401) {
    await localStorage.removeItem("spotify_token");
    window.location.href = "/";
  }
  return Promise.reject(error.response.data);
});

/**
 Spotify Web APIs
 */
export const getUserPlaylists = async (id, params) => {
  const {data} = await SpotifyAPI.get(`/me/playlists?${params}`);
  return data;
};

export const getPlaylistTracks = async (id, params) => {
  const {data} = await SpotifyAPI.get(`/playlists/${id}/tracks?${params}`);
  return data;
}

export const getTrack = async (id) => {
  const {data} = await SpotifyAPI.get(`/tracks/${id}`)
  return data;
}
export const getTrackAudioAnalysis = async (id) => {
  const {data} = await SpotifyAPI.get(`/audio-analysis/${id}`);
  return data;
}

export const getPlaybackState = async () => {
  const {data} = await SpotifyAPI.get('/me/player');
  return data;
}

export const PlayTracks = async (deviceId, config = {}) => {
  await SpotifyAPI.put(`/me/player/play?device_id=${deviceId}`, config);
};

export const getArtist = async (id) => {
  const {data} = await SpotifyAPI.get(`/artists/${id}`);
  return data;
}