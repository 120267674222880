import {usePlayerContext} from "../../context/PlayerContext";
import styles from './player.module.css';
import {
  CgPlayTrackNext, CgPlayTrackPrev, IoPauseSharp, IoPlaySharp
} from "react-icons/all";
import {useNavigate} from "react-router-dom";

const Player = () => {
  const navigate = useNavigate();
  const {state, previous, play, next} = usePlayerContext();

  const showPlaying = () => {
    navigate(`playing/${state.currentTrack?.id}`)
  }

  return (<div className={styles.playerContainer}>
    <div className={styles.player}>
      <div className={styles.info}>
        <div className={styles.title} onClick={showPlaying}>{state.currentTrack?.name || ''}</div>
        <div>{state.currentTrack?.artists[0].name || ''}</div>
      </div>
      <div className={styles.controls}>
        <button
          className={styles.skip}
          onClick={previous}
        >
          <CgPlayTrackPrev color={"#fff"} size={'32px'}/>
        </button>
        <button
          className={styles.play}
          onClick={play}
        >
          <div style={{marginLeft: state.paused ? 5 : 0, marginBottom: -4}}>

            {state.paused ? <IoPlaySharp size={"28px"}/> : <IoPauseSharp size={"28px"}/>}
          </div>
        </button>
        <button
          className={styles.skip}
          onClick={next}
        >
          <CgPlayTrackNext color={"#fff"} size={"32px"}/>
        </button>
      </div>
    </div>
  </div>);
};

export default Player;