import {useMemo} from 'react';
import PlayButton from "../PlayButton/PlayButton";
import styles from './track.module.css'

const Track = ({track, index}) => {

  const img = useMemo(() => {
    return track?.album?.images[2]?.url || "";
  }, [track?.album?.images]);

  return (
    <div className={styles.playlistTrack}>
      <div className={styles.infoContainer}>
        {img !== "" ? <img src={img} alt={track.album.name}/> : null}
        <div className={styles.info}>
          <h4>{track.name}</h4>
          <p>{track.artists.map(a => a.name)}</p>
        </div>
      </div>
      <PlayButton index={index}/>
    </div>
  );
};

export default Track;