import React from 'react';
import {BiPlay} from "react-icons/all";
import {usePlayerContext} from "../../context/PlayerContext";
import {useParams} from "react-router-dom";
import styles from './playbutton.module.css';

const PlayButton = ({index}) => {

  const {onPlayTracks} = usePlayerContext();
  const {id} = useParams();

  const onClick = async () => {
    await onPlayTracks(id, {offset: {position: index}});
  }

  return (
    <div className={styles.playButton} onClick={onClick}>
      <button
        className={styles.btn}
      >
        <BiPlay color={"white"} size={"25px"}/>
      </button>
    </div>
  );
};

export default PlayButton;