function sendMessageToUnity(payload = {}) {
  if (window.vuplex) {
    window.vuplex.postMessage(payload);
  }
};

export const sendMessage = {
  play: () => {
    sendMessageToUnity({type: "PLAY", message: "Player active"})
  },
  pause: () => {
    sendMessageToUnity({type: "PAUSE", message: "Player inactive"})
  },
  next: () => {
    sendMessageToUnity({type: "NEXT", message: "Skipped to next song"})
  },
  previous: () => {
    sendMessageToUnity({type: "PREVIOUS", message: "Skipped previous song"})
  },
  trackPlaying: (data) => {
    sendMessageToUnity({type: "PLAYING_TRACK", data})
  },
  playlists: data => {
    sendMessageToUnity({type: "PLAYLIST_DATA", data})
  },
  sync: data => {
    sendMessageToUnity({type: "SYNC_STATUS", data})
  }
}
