import Playlist from "../../components/Playlist/Playlist";
import {usePlayerContext} from "../../context/PlayerContext";
import styles from './home.module.css';
import usePaginatedData from "../../hooks/usePaginatedData";
import {getUserPlaylists} from "../../scripts/api";
import InfiniteScroll from "react-infinite-scroll-component";

const Home = () => {
  const {onPlayPlaylist} = usePlayerContext();
  const {data, fetchMore} = usePaginatedData(getUserPlaylists, {
    params: {
      limit: 20,
    }
  })

  return (
    <div className={styles.bg}>
      <InfiniteScroll
        next={fetchMore}
        hasMore={!!data?.next}
        loader={null}
        dataLength={data?.items?.length || 0}
        scrollThreshold={0.7}
      >
        <div className={styles.container}>
          {data?.items.map(playlist =>
            <Playlist
              key={`playlist-${playlist.id}`}
              playlist={playlist}
              onPlay={() => onPlayPlaylist(playlist.id)}
            />)}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default Home;