import React from 'react';

const AuthContext = React.createContext();
AuthContext.displayName = 'Auth Context';

const initialState = {
  isLoggedIn: false,
  token: null,
}

const AUTH = {
  SIGN_IN: 1,
  SIGN_OUT: 2,
}

function reducer(state, action) {
  switch (action.type) {
    case AUTH.SIGN_IN: {
      return {
        isLoggedIn: true,
        token: action.payload.token,
      }
    }
    case AUTH.SIGN_OUT: {
      return {
        isLoggedIn: false,
        token: null,
      }
    }
    default:
      console.error("Undefined action")
      return state;
  }
}

const AuthProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const signIn = (token) => {
    dispatch({
      type: AUTH.SIGN_IN, payload: {
        token
      }
    });
  }

  const signOut = () => {
    dispatch({
      type: AUTH.SIGN_OUT
    });
  }

  const value = {
    ...state,
    signIn,
    signOut
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export {AuthProvider, useAuth};