/**
 * The token from the url is only valid for the first hour or so. After that it may expire and a new token will
 * to be request.
 * @returns {string}
 */
export const getUrlAccessToken = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('token');
};

export const processTrackData = (analysis) => {
  if (!analysis) return;
  const sections = analysis.sections.map(({tempo, loudness, start, duration, pitches, timbre, confidence}) => ({
    tempo, loudness, start, duration, pitches, timbre, confidence
  }));
  const {bars, beats, segments, tatums, track} = analysis;
  return {
    track,
    bars,
    beats,
    sections,
    segments,
    tatums
  }
};