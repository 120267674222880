import React from 'react';
import {useNavigate} from 'react-router-dom';
import {usePlayerContext} from "../../context/PlayerContext";
import styles from './playlist.module.css';

const Playlist = ({playlist, onPlay}) => {
  const navigate = useNavigate()
  const {onSelectPlaylist} = usePlayerContext();

  const handleShowPlaylist = async () => {
    navigate(`/playlist/${playlist.id}`)
    await onSelectPlaylist(playlist.id)
  }

  const handlePlayPlaylist = (e) => {
    e.stopPropagation()
    onPlay(playlist.id)
  }


  return (
    <div className={styles.playlist} onClick={handleShowPlaylist}>
      <div>
        {playlist.images.length > 0 && <img width={'100%'} alt="img" src={playlist.images[0].url}/>}
      </div>
      <div className={styles.info}>
        <div>
          <h5>{playlist.name}</h5>
          <span>Playlist | {playlist.owner.display_name}</span>
        </div>
        <button
          className={styles.spotifyBtn}
          onClick={handlePlayPlaylist}
        >
          Play
        </button>
      </div>
    </div>
  );
};

export default Playlist;