import {
  Routes, Route
} from "react-router-dom";
import Home from '../pages/Home';
import PlaylistDetail from "../pages/PlaylistDetail/PlaylistDetail";
import Login from "../pages/Login";
import RequireAuth from "../hoc/RequireAuth";
import {useAuth} from "../context/AuthContext";
import Player from "../components/Player";
import NowPlaying from "../pages/NowPlaying";

function Router() {
  const {isLoggedIn} = useAuth();

  return (
    <>
      <Routes>
        <Route path={"/"} element={<Login/>}/>
        <Route path={"/home"} element={
          <RequireAuth>
            <Home/>
          </RequireAuth>
        }/>
        <Route path={"playlist/:id"} element={
          <RequireAuth>
            <PlaylistDetail/>
          </RequireAuth>
        }/>
        <Route path={"playing/:id"} element={
          <RequireAuth>
            <NowPlaying />
          </RequireAuth>
        }/>
      </Routes>
      {isLoggedIn && <Player />}
      <img src={'spotify-logo.png'} style={{
        width: 100, height: 30,
        position: 'absolute',
        top: 10,
        right: 10
      }} alt={"spotify"}/>
    </>
  );
}

export default Router;
