import {useEffect, useState} from 'react';

const defaultOptions = {
  params: {},
  id: null,
  config: {
    limit: 0,
    offset: 0,
  }
}

const serialize = (obj) => {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

const usePaginatedData = (endpoint, options = defaultOptions) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextParam, setNextParam] = useState(null);

  useEffect(() => {
    if (typeof endpoint !== "function")
      throw new Error("endpoint must be a function")
    setIsLoading(true);
    endpoint(options.id, serialize(options.params)).then(data => {
      setNextParam(data.next ? data.next.split("?")[1] : null)
      setData(data)
    }).catch(e => setError(e))
      .finally(() => {
        setIsLoading(false);
      })
  }, [endpoint])

  function fetchMore() {
    if (nextParam === null) return;
    setIsLoading(true);
    endpoint(options.id, nextParam).then(data => {
      setData(prev => ({
        ...data,
        items: [...prev.items, ...data.items],
      }))
      setNextParam(data.next ? data.next.split("?")[1] : null)
    }).catch(e => {
      setError(e);
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return {
    data,
    fetchMore,
    isLoading,
    error,
  }
};

export default usePaginatedData;