import {useNavigate, useParams} from "react-router-dom";
import Track from "../../components/Track/Track";
import {IoChevronBack} from "react-icons/all";
import styles from './playlistdetail.module.css';
import usePaginatedData from "../../hooks/usePaginatedData";
import {getPlaylistTracks} from "../../scripts/api";
import InfiniteScroll from "react-infinite-scroll-component";

const PlaylistDetail = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {data, fetchMore} = usePaginatedData(getPlaylistTracks, {
    id: id,
    params: {
      limit: 20,
      offset: 0,
    }
  })

  const handlePressBack = () => {
    navigate(-1)
  }

  return (
    <div className={styles.container}>
      <div
        onClick={handlePressBack}
        className={styles.btnReturn}
        role={"button"}
      >
        <IoChevronBack color={"white"} size={"30px"}/>
      </div>
      <InfiniteScroll
        next={fetchMore}
        hasMore={!!data?.next}
        loader={null}
        dataLength={data?.items?.length || 0}
        scrollThreshold={0.7}
      >
        <div className={styles.playlistDetail}>
          {
            data?.items.map(({track}, index) =>
              <Track
                key={track.id}
                track={track}
                index={index}
              />)
          }
        </div>
      </InfiniteScroll>
    </div>

  );
};

export default PlaylistDetail;