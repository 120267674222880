import {BrowserRouter} from "react-router-dom";
import Router from "./router/Router";
import {PlayerContextProvider} from "./context/PlayerContext";
import {AuthProvider} from "./context/AuthContext";
import './scripts/vuplex';

function App() {

  return (
    <div className="bg-dark">
      <BrowserRouter>
        <AuthProvider>
          <PlayerContextProvider>
            <Router/>
          </PlayerContextProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
